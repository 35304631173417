"use client";

import React, { useContext } from "react";
import {
  NavbarContextProps,
  NavbarProviderProps,
  Workspace,
} from "./navbar-context.types";

export const NavbarContext = React.createContext<NavbarContextProps | null>(
  null,
);

export const NavbarProvider = ({
  children,
  defaultValues,
}: NavbarProviderProps) => {
  const [currentWorkspace, setCurrentWorkspace] =
    React.useState<Workspace | null>(defaultValues?.currentWorkspace ?? null);
  const [workspaces, setWorkspaces] = React.useState<Workspace[]>(
    defaultValues?.workspaces!,
  );

  const contextValue = React.useMemo(
    () => ({
      ...defaultValues,
      currentWorkspace,
      setCurrentWorkspace,
      workspaces,
      setWorkspaces,
    }),
    [currentWorkspace, setCurrentWorkspace, workspaces, setWorkspaces],
  );

  return (
    <NavbarContext.Provider value={contextValue}>
      {children}
    </NavbarContext.Provider>
  );
};

export const useNavbarContext = () => {
  const context = useContext(NavbarContext) ?? ({} as NavbarContextProps);
  if (context == null) {
    throw new Error(
      "useNavbarContext can only be used within a NavbarProvider",
    );
  }

  return context;
};

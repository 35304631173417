"use client";
import React from "react";
import Image from "next/image";
import Link from "next/link";
import useBreakpoint from "~/hooks/useBreakpoint";
import { cn } from "~/utils/cn";

import { LoggedOutDropdownMenu } from "../logged-out-dropdown-menu";
import { NavBarTemplateLogoOnly } from "./components";
import { NavBarTemplateProps } from "./navbar-template.types";
import { getCompanyLogoProps } from "./navbar-template.utils";
import { NavbarButtons } from "./components/navbar-buttons";
import { useSession } from "next-auth/react";

export const NavBarTemplate = ({
  searchInput,
  navLinks,
  accountButton,
  menuButton,
  isJobseeker,
  isLogoOnly,
  isLoggedOut = false,
  className,
}: NavBarTemplateProps) => {
  const { lg } = useBreakpoint();
  const isJobseekerDesktop = lg && isJobseeker;
  const isEmployerMobile = !lg && !isJobseeker;
  const isJobseekerMobile = !lg && isJobseeker;
  const isExtendedLogo =
    isJobseekerDesktop ||
    isEmployerMobile ||
    (isJobseekerMobile && isLoggedOut);
  const showLoggedOutMenu = isJobseekerMobile && isLoggedOut;
  const { status } = useSession();

  const isLoading = status === "loading";

  if (isLogoOnly) {
    return (
      <NavBarTemplateLogoOnly isLoggedOut={isLoggedOut} isLoading={isLoading} />
    );
  }

  return (
    <div
      className={cn(
        "flex w-full items-center justify-between border-b border-neutral-10 bg-brand-primary-light-00 px-4 py-[0.375rem]",
        {
          "bg-neutral-00": isJobseeker,
        },
        className,
      )}
    >
      <div className="flex w-full items-center gap-6 lg:w-auto">
        {showLoggedOutMenu && <LoggedOutDropdownMenu />}
        <div
          className={cn("overflow-hidden", {
            "h-5 w-24": isExtendedLogo,
            "w-9": !isExtendedLogo,
            "flex w-full justify-center": showLoggedOutMenu,
          })}
        >
          <Link href="/">
            <Image
              alt={`scalis logo ${isExtendedLogo ? "extended" : "compact"}`}
              {...getCompanyLogoProps(!!isExtendedLogo)}
              className={cn({
                "h-5": isExtendedLogo,
              })}
            />
          </Link>
        </div>
        {!isEmployerMobile && !isLoggedOut && !isLoading && (
          <div className="w-full lg:w-[15.625rem]"> {searchInput} </div>
        )}
      </div>
      <div className="flex items-center gap-4">
        <div className="flex gap-2">
          {lg &&
            navLinks.map(({ label, href }, index) => (
              <Link
                aria-label={label}
                className={cn(
                  "whitespace-nowrap rounded-lg p-2 text-sm/6 font-medium text-neutral-primary  hover:bg-brand-primary-light-10 active:bg-button-brand-primary-light-hover",
                  {
                    "hover:bg-neutral-10 active:bg-neutral-20": isJobseeker,
                  },
                )}
                href={href}
                key={`navbar-link-index-${index}`}
              >
                {label}
              </Link>
            ))}
        </div>

        {!isLoading && (
          <NavbarButtons
            isLoggedOut={isLoggedOut}
            accountButton={accountButton}
            menuButton={menuButton}
            isJobseekerDesktop={!!isJobseekerDesktop}
            isJobseeker={!!isJobseeker}
          />
        )}
      </div>
    </div>
  );
};

import React from "react";
import { Slot } from "@radix-ui/react-slot";
import { BodyProps } from "./body.types";
import { bodyClassName } from "./body.styles";

export const Body: React.FC<BodyProps> = ({
  asChild,
  size,
  underline,
  type,
  center,
  textAlign,
  ...props
}) => {
  const Comp = asChild ? Slot : "p";

  return (
    <Comp
      className={bodyClassName({ size, underline, type, center, textAlign })}
      {...props}
    />
  );
};

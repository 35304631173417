"use client";

import React from "react";
import { useSession } from "next-auth/react";
import { isEmpty } from "lodash";

import { AccountDropdownMenu } from "~/scalis-components/core/navbar/account-dropdown-menu/account-dropdown-menu";
import { NavBarTemplate } from "~/scalis-components/core/navbar/navbar-template/navbar-template";
import { SearchMenu } from "~/scalis-components/core/navbar/search-menu/search-menu";
import { WorkspacesDropdownMenu } from "~/scalis-components/core/navbar/workspaces-dropdown-menu";
import { CreateWorkspaceButton } from "~/scalis-components/core/navbar/workspaces-dropdown-menu/components/create-workspace-button/create-workspace-button";

import { useNavLinks } from "./navbar-default.hooks";
import { useNavbarContext } from "~/scalis-components/core/navbar/navbar-context";

export const NavbarDefault = ({ className }: { className?: string }) => {
  const { data: session, status } = useSession();
  const isSignedIn = status === "authenticated";
  const isLoading = status === "loading";
  const navLinks = useNavLinks();
  const isJobseeker = !session?.user?.workspace?.currentCompany || !isSignedIn;
  const searchInput = isSignedIn ? <SearchMenu /> : null;
  const { currentWorkspace } = useNavbarContext();

  const AccountButton: React.FC = () => {
    if (!isSignedIn) {
      return null;
    }

    return (
      <AccountDropdownMenu
        isJobSeeker={isJobseeker}
        email={session?.user?.email!}
        imageSrc={session?.user?.image!}
        name={session?.user?.name!}
        userId={Number(session?.user?.id!)}
      />
    );
  };

  const hasWorkspaceSelected = isSignedIn && !isEmpty(currentWorkspace);

  const workspaceButton = hasWorkspaceSelected ? (
    <WorkspacesDropdownMenu companyId={currentWorkspace?.id} />
  ) : (
    <CreateWorkspaceButton />
  );

  return (
    <NavBarTemplate
      isLogoOnly={isLoading}
      className={className}
      isJobseeker={isJobseeker}
      isLoggedOut={!isSignedIn}
      accountButton={<AccountButton />}
      navLinks={navLinks}
      menuButton={workspaceButton}
      searchInput={searchInput}
    />
  );
};

import { tv } from "tailwind-variants";
import { TitleSize } from "./title.types";

export const titleClassName = tv({
  variants: {
    size: {
      [TitleSize.extraLarge]: "text-[32px] font-normal leading-10",
      [TitleSize.large]: "text-2xl font-medium leading-8",
      [TitleSize.medium]: "text-xl text-medium leading-8",
      [TitleSize.small]: "text-lg text-medium leading-6",
      [TitleSize.extraSmall]: "text-base text-medium leading-6",
    },
    center: { true: "text-center" },
  },
  defaultVariants: {
    size: TitleSize.medium,
  },
});

import Image from "next/image";
import Link from "next/link";
import React from "react";
import {
  Button,
  ButtonKind,
  ButtonWidth,
} from "~/scalis-components/core/button";
import ExtendedLogo from "/public/images/SCALIS_LOGO_BLUE.svg";
import { Divider } from "~/scalis-components/core/divider";
import { Body, BodySize, TextAlign } from "~/scalis-components/core/typography";
import { useRouter } from "next/navigation";

export const LoggedOutDropdownMenuContent: React.FC = () => {
  const router = useRouter();
  return (
    <>
      <div className="mt-[-1rem] flex flex-col gap-4 p-4">
        <div className="w-28">
          <Image src={ExtendedLogo} height={42} alt="SCALIS Logo" />
        </div>
        <div className="flex flex-col gap-2 text-neutral-primary">
          <Link href="/" className="text-lg/8 font-medium ">
            Home
          </Link>
          <Link href="/jobs" className="text-lg/8 font-medium ">
            Jobs
          </Link>
          <Link href="/contact" className="text-lg/8 font-medium ">
            Contact
          </Link>
        </div>

        <div className="flex w-full flex-row gap-2">
          <Button
            kind={ButtonKind.tertiary}
            width={ButtonWidth.half}
            aria-label="Login Button"
            onClick={() => router.push("/login")}
          >
            <Body size={BodySize.small} textAlign={TextAlign.center}>
              Login
            </Body>
          </Button>
          <Button
            kind={ButtonKind.primary}
            width={ButtonWidth.half}
            aria-label="Signup Button"
            onClick={() => router.push("/signup")}
          >
            <Body size={BodySize.small} textAlign={TextAlign.center}>
              Cancel
            </Body>
          </Button>
        </div>
      </div>
      <Divider />
      <div className="flex flex-col gap-4 p-4 text-sm/6 font-normal text-neutral-secondary">
        <Link href="/tos">Terms & Conditions</Link>
        <Link href="/jobs">Privacy Policy</Link>
        <Link href="https://help.scalis.ai/">
          Data Processing Agreement (DPA)
        </Link>
      </div>
    </>
  );
};

import Link from "next/link";
import React from "react";
import { Divider } from "~/scalis-components/core/divider";
import { useSession } from "next-auth/react";
import { useNavbarContext } from "../../navbar-context";

export const WorkspacesDropdownMenuFooter: React.FC = () => {
  const { update } = useSession();
  const { setCurrentWorkspace } = useNavbarContext();

  return (
    <div className="flex w-full flex-col">
      {/* TODO: Add this back when we have a page for the workspaces */}
      {/* <div className="flex w-full items-center gap-1 px-4 py-2">
        <Link
          href={`/workspaces`}
          className="flex w-full items-center justify-between gap-1 px-4 py-2 text-brand-primary-rest  hover:cursor-pointer"
        >
          <div className="flex items-center gap-2 text-sm font-medium">
            All Accounts
          </div>

          <i className="fa-solid fa-chevron-right" />
        </Link>
      </div> */}

      <Divider />

      <div className="flex  w-full items-center gap-1 px-4 py-2">
        <Link
          href="#"
          onClick={async e => {
            e.preventDefault();

            await update({
              subdomain: null,
            });

            // TODO: Clear subdomain from URL
            setCurrentWorkspace(null);
          }}
          className="flex w-full items-center justify-between gap-1 px-4 py-2 text-brand-primary-rest  hover:cursor-pointer"
        >
          <div className="flex items-center gap-2 text-sm font-medium">
            Switch to Job Seeker
          </div>

          <i className="fa-regular fa-arrow-right-from-bracket" />
        </Link>
      </div>
    </div>
  );
};

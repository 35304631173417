export enum BodySize {
  small = "small",
  medium = "medium",
  large = "large",
}

export enum BodyType {
  emphasys = "emphasys",
  basic = "basic",
}

export enum TextAlign {
  left = "left",
  center = "center",
  end = "end",
}

export interface BodyProps {
  children: React.ReactNode;
  size?: BodySize;
  underline?: boolean;
  type?: BodyType;
  asChild?: boolean;
  center?: boolean;
  className?: string;
  textAlign?: TextAlign;
}

import { tv } from "tailwind-variants";
import { CaptionType } from "./caption.types";

export const captionClassName = tv({
  base: "text-xs text-neutral-secondary leading-4",
  variants: {
    type: {
      [CaptionType.emphasys]: "font-medium",
      [CaptionType.basic]: "font-normal",
    },
    underline: {
      true: "underline",
      false: "no-underline",
    },
    center: { true: "text-center" },
  },
  defaultVariants: {
    type: CaptionType.basic,
    underline: false,
  },
});

export enum CaptionType {
  emphasys = "emphasys",
  basic = "basic",
}

export interface CaptionProps {
  children: React.ReactNode;
  underline?: boolean;
  type?: CaptionType;
  asChild?: boolean;
  center?: boolean;
}

import { Icon } from "../../icon";
import { HamburgerMenuButtonProps } from "./hamburger-menu-button.types";

export const HamburgerMenuButton = ({
  onClick,
  ...props
}: HamburgerMenuButtonProps) => {
  return (
    <Icon
      icon="fa-solid fa-bars"
      onClick={onClick}
      aria-label={props["aria-label"] ?? "hamburger menu button"}
    />
  );
};
